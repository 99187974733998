<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <Breadcrumb :datas='breadcrumb_data'/>
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Card-->
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t('miracle_deal.striker_list') }}
              </h3>
            </div>
          </div>
          <div class='card-body'>
            <div v-if="isLoading">
              <div class="text-center">{{ $t('commons.data_loading') }}...</div>
            </div>
            <table class="table table-striped table-bordered collapsed" style="width:100%" ref="kt_datatable" v-else>
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{{ $t('commons.currency_code') }}</th>
                  <th scope="col">{{ $t('commons.quantity') }}</th>
                  <th scope="col">{{ $t('miracle_deal.fee') }}</th>
                  <th scope="col">{{ $t('commons.duration') }}</th>
                  <th scope="col">{{ $t('miracle_deal.customer_ratio') }}</th>
                  <th scope="col">{{ $t('miracle_deal.countdown') }}</th>
                  <th scope="col">{{ $t('commons.status') }}</th>
                  <th scope="col" class="text-right">{{ $t('commons.transactions') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in keeper" :key="'keeper-' + index">
                  <th class="font-weight-bolder">{{ item.id }}</th>
                  <td>
                    <span style="width: 250px;">
                      <div class="d-flex align-items-center">
                        <div class="symbol symbol-40 flex-shrink-0">								
                          <div class="media align-items-center">
                            <img :src="`${iconUrl}${item.currencyCode.toLowerCase()}.png`" height="30" alt="" class="cc mr-2 font-large-2 warning d-none d-md-block">
                          </div>				
                        </div>				
                        <div class="ml-3">	
                          <div class="text-dark-75 font-weight-bold line-height-sm">{{ item.currencyCode }}</div>		
                        </div>
                      </div>
                    </span>
                  </td>
                  <td>{{ item.quantity }}</td>
                  <td class="text-muted">% {{ item.fee }}</td>
                  <td class="text-muted">{{ item.duration }} {{ textDuration(item.durationType) }}</td>
                  <td class="text-muted">% {{ item.ratio }}</td>
                  <td>
                    <span style="width: 250px;">
                      <div class="d-flex align-items-center">
                        <div class="ml-2">
                          <div v-if="item.processDate" class="text-primary font-weight-bold line-height-sm">{{ item.processDate.date  | dateFormat }}</div>
                          <div class="font-size-sm text-dark-50">{{ item.time_ago }}</div>
                        </div>
                      </div>
                    </span>
                  </td>
                  <td>
                    <span :class="['label label-lg label-inline', item.status === 0 ? 'label-light-danger' : item.status === 1 ? 'label-light-warning' : 'label-light-primary'] ">
                      {{ item.status === 0 ? $t('miracle_deal.close') : item.status === 1 ? $t('miracle_deal.waiting') : $t('miracle_deal.in_progress', {"space": "&nbsp;"}) }}
                    </span>
                  </td>
                  <td class="text-right no-sort">
                    <button v-if="item.status === 1" @click="showModal(item.id)" class="btn btn-icon btn-light btn-hover-primary btn-sm bg-primary" :title="$t('miracle_deal.keeper_new')">
                      <i class="la la-eye text-white"></i>
                    </button>
                    <button v-if="item.status !== 0" type="button" @click="deleteKeeper(item.id)"
                            class="btn btn-icon btn-light btn-hover-danger btn-sm bg-danger ml-3" :title="$t('commons.delete')">
                      <i class="la la-trash text-white"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
    <Modal :postType="`striker`"/>
    <!--end::Entry-->
  </div>
</template>

<script>
import dateFormat from '@/config/_date-format';
import {mapState, mapActions} from 'vuex';
import Swal from 'sweetalert2'
import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import Modal from '@/components/miracleDeal/Modal';
import $ from "jquery";


export default {
  name: 'StrikerList',
  data() {
    return {
      isLoading:true,
      iconUrl: "https://cdn.test.ofinans.com/images/currency/"
    }
  },
  components: {
    Breadcrumb,
    Modal,
  },
  filters: {
    dateFormat
  },
  computed: {
    ...mapState({
      keeper: state => state.keeper.keeperList,
      lang: (state) => state.global.lang
    }),
    breadcrumb_data() {
      return {
        title:  this.$t('miracle_deal.title'),
        subtitle: [
          this.$t('miracle_deal.striker_list')
        ]
      }
    }
  },
  methods: {
    ...mapActions('keeper', ['DELETE_KEEPER']),
    textDuration(id) {
      switch (id) {
        case 0:
          return 'min';
        case 1:
          return 'h';
        case 2:
          return 'd';
        case 3:
          return 'w';
        case 4:
          return 'm';
        default:
          return 'y';
      }
    },
    deleteKeeper(id) {
      const vm= this;
      Swal.fire({
        title: this.$t('commons.are_you_sure'),
        text: this.$t('commons.wont_be_able_to_revert'),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t('commons.yes_delete_it'),
        cancelButtonText: this.$t('commons.cancel'),
      }).then(function (result) {
        if (result.value) {
          vm.DELETE_KEEPER(id);
        }
      });
    },
    showModal(id) {
      this.$store.commit('striker/SET_STRIKER_ID', id);
      this.$store.commit('keeper/statusModal', true);
      this.$store.dispatch('global/SEARCH_USER_KEEPER');
    },
  },
  created() {
    const self = this;
    self.$store.dispatch('keeper/GET_KEEPER').then(function () {
      self.isLoading = false;
      self.$nextTick(function (){
        $(self.$refs['kt_datatable']).DataTable({
          responsive: true,
          dom: 'lfrtip',
          order: [[ 0, 'desc' ]],
          pageLength: 10,
          lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
          columnDefs: [
            { orderable: false, targets: Array.from({ length:8 },(v,k) => k+1) },
            { responsivePriority: 1, targets: 0 },
            { responsivePriority: 2, targets: -1 }
          ],
          language: {
              url: `/assets/languages/datatable/${this.lang}.json`
          }
        });
      })

    })
  },
}
</script>
<style lang="scss">
</style>
